import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import Layout from '../components/layout'

const useStyles = makeStyles(() => ({
	header: { fontSize: '2rem' },
	items: { margin: '0.5rem 0' }
}))

const onChange = setter => e => setter(e.target.value)

const Home = () => {
	const [roomName, setRoomName] = useState('')
	const [password, setPassword] = useState('')
	const classes = useStyles()

	return (
		<Layout alignItems='center' justifyContent='center'>
			<Box
				component='form'
				display='flex'
				flexDirection='column'
				alignItems='stretch'
				width={{ md: '50vw', lg: '25vw' }}
			>
				<Typography variant='h1' className={[classes.header, classes.items]}>
					Join Game
				</Typography>
				<TextField
					className={classes.items}
					value={roomName}
					onChange={onChange(setRoomName)}
					variant='outlined'
					label='Room Name'
					placeholder='My fancy room'
					required
				/>
				<TextField
					className={classes.items}
					value={password}
					onChange={onChange(setPassword)}
					variant='outlined'
					label='Password'
					type='password'
				/>
				<Button className={classes.items} variant='contained' color='primary'>
					Join
				</Button>
			</Box>
		</Layout>
	)
}

export default Home
