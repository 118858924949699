import React, { useState, useEffect, useRef, useContext } from 'react'
import io from 'socket.io-client'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import buzzerSound from '../assets/buzzer.mp3' // Sound from http://www.freesoundslibrary.com

import SettingsContext from '../contexts/settings'
import Layout from '../components/layout'

const useStyles = makeStyles(() => ({
	buzzer: {
		backgroundColor: 'red',
		borderRadius: '100%',
		width: '75vmin',
		maxWidth: '500px',
		height: '75vmin',
		maxHeight: '500px',
		color: 'white',
		fontSize: '1rem',
		'&:hover': {
			backgroundColor: 'darkred'
		}
	},
	reset: {
		position: 'fixed',
		width: 30,
		height: 30,
		bottom: 0,
		left: 0,
		zIndex: 99999
	}
}))

const Game = () => {
	const { SOCKET_URL } = useContext(SettingsContext)
	const classes = useStyles()
	const [socket, setSocket] = useState()
	const [connected, setConnected] = useState()
	const [username, setUsername] = useState()
	const [answering, setAnswering] = useState()
	const [saved, setSaved] = useState()
	const buzzerRef = useRef()

	const onChange = e => setUsername(e.target.value)
	const onClick = () => setSaved(true)
	const onBuzz = () => {
		buzzerRef.current.play()
		socket.send(username)
	}
	const onReset = () => socket.send('reset')

	useEffect(() => {
		const socket = io(SOCKET_URL, { forceNew: true })

		socket.on('connect', () => {
			setConnected(true)
			setSocket(socket)
		})

		socket.on('message', (message) => {
			if (message === 'reset') {
				setAnswering(null)
			} else if (!answering) {
				setAnswering(message)
			}
		})
	}, [])

	if (!connected) {
		return (
		<Layout padding='2rem' display='flex' flexDirection='column' alignItems='center'>
				<Typography variant='h3'>Connecting</Typography>
			</Layout>
		)
	}

	return (
		<Layout padding='2rem'>
			{(!username || !saved) ? (
				<>
					<TextField
						variant='outlined'
						label="Username"
						placeholder="Super Cool"
						onChange={onChange}
					/>
					<br />
					<Button color='primary' variant='contained' onClick={onClick}>
						Save Name
					</Button>
				</>
			) : (
				<Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' flex={1}>
					<audio src={buzzerSound} ref={buzzerRef} />
					{answering ? (
						<Typography variant='h6'>{answering}</Typography>
					): (
						<Button className={classes.buzzer} onClick={onBuzz}>{username}</Button>
					)}
				</Box>
			)}
			<button className={classes.reset} onClick={onReset} color='primary' variant='contained' />
		</Layout>
	)
}

export default Game
