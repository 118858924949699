import React from 'react'
import Box from '@material-ui/core/Box'

import Header from './header'

const Layout = ({ children, ...rest }) =>
	<Box display='flex' flexDirection='column' minHeight='100vh'>
		<Header />
		<Box component='main' flex={1} display='flex' flexDirection='column' {...rest}>{children}</Box>
	</Box>

export default Layout
