import React from 'react'
import { path } from 'ramda'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'

import Layout from '../components/layout'
import List from '../components/list'

const QUERY = gql`
{
	allQuestions {
		edges {
			node {
				id
				description
				public
			}
		}
	}
	allQuizzes {
		edges {
			node {
				id
				name
			}
		}
	}
}
`

const useStyles = makeStyles(() => ({
	skeleton: {
		marginTop: '1rem'
	}
}))

const Admin = () => {
	const { loading, error, data } = useQuery(QUERY)
	const classes = useStyles()

	console.log(data)
	console.log(path('allQuestions.edges', data))

	return (
		<Layout>
			{error ? (
				<Typography>Error</Typography>
			) : (
				<>
					<Box marginTop='2rem' paddingX='1rem'>
						<Typography variant='h2'>Questions</Typography>
						<List
							loading={loading}
							items={path('allQuestions.edges', data)}
							renderItem={({ node }) => (
								<ListItem color='primary' variant='contained' button>
									<ListItemText>{node.description}</ListItemText>
								</ListItem>
							)}
						/>
					</Box>

					<Box marginTop='2rem' paddingX='1rem'>
						<Typography variant='h2'>Quizzes</Typography>
						<List
							loading={loading}
							items={path('allQuestions.edges', data)}
							renderItem={({ node }) => (
								<ListItem color='primary' variant='contained' button>
									<ListItemText>{node.description}</ListItemText>
								</ListItem>
							)}
						/>
					</Box>
				</>
			)}
		</Layout>
	)
}

export default Admin
