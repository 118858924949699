import React from 'react'
import range from 'lodash/range'
import { map } from 'ramda'
import Skeleton from '@material-ui/lab/Skeleton'
import MUIList from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'

const List = ({ loading, initialNumber = 5, items = [], renderItem = () => null }) => {
	return (
		<MUIList>
			{loading && range(initialNumber).map(i => (
				<ListItem key={i}>
					<Skeleton variant='rect' style={{ margin: '1rem 0', backgroundColor: '#ccc' }} />
				</ListItem>
			))}
			{!loading && map(renderItem, items)}
		</MUIList>
	)
}

export default List
