import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(() => ({
	title: {
		fontSize: '2rem'
	}
}))

const Header = () => {
	const classes = useStyles()

	return (
		<AppBar position="static" component="header">
			<Toolbar>
				<Typography className={classes.title}>
					Bzz It
				</Typography>
			</Toolbar>
		</AppBar>
	)
}

export default Header
