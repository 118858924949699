import 'typeface-roboto'

import React from 'react'
import ReactDOM from 'react-dom'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { Router } from '@reach/router'
import ApolloClient from 'apollo-boost'
import { ApolloProvider } from '@apollo/react-hooks'

import SettingsContext from './src/contexts/settings'
import Admin from './src/pages/admin'
import Game from './src/pages/game'
import Home from './src/pages/home'

if (process.env.ENV === 'dev') {
	console.log('========================================')
	console.log('ENV', process.env.ENV)
	console.log('GRAPHQL_URL', process.env.GRAPHQL_URL)
	console.log('SOCKET_URL', process.env.SOCKET_URL)
	console.log('========================================')
}

const SETTINGS = {
	ENV: process.env.ENV,
	GRAPHQL_URL: process.env.GRAPHQL_URL,
	SOCKET_URL: process.env.SOCKET_URL
}

const client = new ApolloClient({
	uri: process.env.GRAPHQL_URL
});

const theme = createMuiTheme({
	typography: {
		fontFamily: 'Roboto, sans-serif'
	}
})

class HelloMessage extends React.Component {
	render() {
		return (
			<ApolloProvider client={client}>
				<MuiThemeProvider theme={theme}>
					<SettingsContext.Provider value={SETTINGS}>
						<Router>
							{/* <Home path='/' /> */}
							<Game path='/' />
							{/* <Admin path='/admino' /> */}
						</Router>
					</SettingsContext.Provider>
				</MuiThemeProvider>
			</ApolloProvider>
		)
	}
}

var mountNode = document.getElementById('app')
ReactDOM.render(<HelloMessage name='Jane' />, mountNode)
